<template>
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Aparência do checkout</h1>
      <p class="text-gray-500">
        Ajuste a aparência da sua loja na barra do topo no checkout da Parcelex.
      </p>
    </header>
    <div>
      <div>
        <div class="divide-y divide-gray-200">
          <div class="p-5 pl-0">
            <label class="form-label">Logotipo da loja</label>
            <div>
              <input
                ref="inputFile"
                type="file"
                accept="image/*"
                class="hidden"
                @input="importImage"
              />
              <px-button @click="selectFile">
                <PhotographIcon size="24"/>
                <span>Selecionar arquivo</span>
              </px-button>
              <div
                v-show="showRequiredError"
                class="text-red-700 text-sm mt-1"
              >
                Selecione um logotipo para a sua loja
              </div>
              <ul class="text-gray-500 text-sm leading-5 mt-2">
                <li>Dimensões mínimas: 100x32px</li>
                <li>Formatos aceitos: SVG, PNG, JPG</li>
                <li>Tamanho máximo: 2MB</li>
              </ul>
            </div>
          </div>
          <div class="p-5 pl-0">
            <px-input-color-picker
              :defaultColor="logoBackgroundColorDefault"
              v-model="logoBackgroundColor"
            >
              Cor de fundo da barra
              <px-icon
                name="question-mark.svg"
                class="ml-1 cursor-pointer"
              />
            </px-input-color-picker>
          </div>
        </div>
      </div>
      <div class="mt-5" :class="{ 'hidden': !previewShow }">
        <div class="font-semibold mb-2">Pré-visualização</div>
        <div
          class="rounded-6 rounded-md px-4 py-1 h-14 flex items-center"
          :style="{'background-color': logoBackgroundColor}"
        >
          <img
            :src="imageURL"
            class="h-full"
            alt="Pré-visualização da logotipo"
          />
        </div>
      </div>
    </div>
    <footer class="flex justify-end space-x-3 mt-6">
      <px-button href="/onboarding/contatos">
        <ChevronLeftIcon slot="left" size="20"/>
        <span>Voltar</span>
      </px-button>
      <px-button kind="primary" @click="finishPreInstall" :disabled="clicked">
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
    <Loader :loading="loading"/>
  </template-onboarding>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { PhotographIcon } from '@vue-hero-icons/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/solid';
import Loader from '@/components/common/Loader.vue';
import UtilMixin from '@/mixins/util';
import TemplateOnboarding from './TemplateOnboarding.vue';
import { PxButton, PxInputColorPicker, PxIcon } from '../../../components/custom';

export default {
  name: 'CheckoutAppearance',
  metaInfo: {
    title: 'Aparência do checkout',
  },
  components: {
    TemplateOnboarding,
    PxButton,
    PxInputColorPicker,
    PxIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    PhotographIcon,
    Loader,
  },
  mixins: [UtilMixin],
  data: () => ({
    clicked: false,
    currentOnboardingStep: 5,
    previewShow: false,
    loading: false,
    logoBackgroundColorDefault: '#D7EDF5',
    logoBackgroundColor: '',
    imageURL: '',
    showRequiredError: false,
  }),
  computed: {
    ...mapState('onboarding', ['onboardingSteps']),
  },
  methods: {
    ...mapMutations('onboarding', ['setLogoData']),
    ...mapActions('onboarding', ['saveFinalStep']),
    getInvalidInputList() {
      const list = [];
      if (!this.imageURL) list.push('imageURL');
      return list;
    },
    selectFile() {
      this.$refs.inputFile.click();
    },
    importImage() {
      const [imageFile] = this.$refs.inputFile.files;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageURL = event.target.result;
      };
      reader.readAsDataURL(imageFile);
      this.previewShow = true;
      this.showRequiredError = false;
    },
    async finishPreInstall() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.showRequiredError = true;
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        this.clicked = true;
        this.loading = true;

        this.setLogoData({
          imageURL: this.imageURL,
          backgroundColor: this.backgroundColor || this.logoBackgroundColorDefault,
        });
        await this.saveFinalStep();

        this.loading = false;
        this.clicked = false;
      }
    },
  },
};
</script>
